/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Juan Hernandez <juan@startuplab.mx>, October 2020
 */

// Regular imports
import React from 'react';
import KomunidadApi from '../api/api.js';
import {Tab,Nav} from 'react-bootstrap';
import Swal from 'sweetalert2';

// Custom styling
import '../scss/pages/_style.css';
import '../scss/_sidebar.scss';
import '../scss/pages/pages.scss';
import '../scss/colors/default-dark.scss';

//Components
import HeaderFree from '../components/HeaderFree';
import SideBar from '../components/SideBar';
import TopSideBar from '../components/TopSideBar';
import ProgramsPicker from '../components/ProgramsPicker';

// Validations
import { isUserProfileComplete } from '../utils/Utils.js';
import Footer from "../components/Footer";

class LiveFreeView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            authorized:true,
            sidebarVisible: true,
            sidebarStatus: false,
			      showTopbar: true,
            preuser_email: '',
            me:{},
            resources:[],
            resourcesLoadState:'default',
            selectedId:[0],
            programName:[0],
            showWelcomeModal:true,
            loading: false,
            creoVideos: [],
            currentCreoVideo:{

            },
            opportunities:[],
            opportunitiesLoaded:false
        }
        this.handleResizeBar               = this.handleResizeBar.bind(this);
        this.handleHeaderResize            = this.handleHeaderResize.bind(this);
        this.redirectToEditProfile         = this.redirectToEditProfile.bind(this);
        this.onProgramSelected             = this.onProgramSelected.bind(this);
        this.handleSelectedTab             = this.handleSelectedTab.bind(this);
        this.getLiveViewByProgram          = this.getLiveViewByProgram.bind(this);
        this.openConfirmationModal         = this.openConfirmationModal.bind(this);
        this.getVideos                     = this.getVideos.bind(this);
    }

    componentDidMount(){
        KomunidadApi.getMe().then( (res) => {
        if(res.valid_token){
            if(!isUserProfileComplete(res.user)){
                this.redirectToEditProfile();
            }else{
              this.props.history.push('/live');
            }
        }else{
          let search = this.props.location.search;
          let params = new URLSearchParams(search);
          let email = params.get('email');
          let name = params.get('name');
    
          if (email !== null) {
            this.setState({
              preuser_email: email,
              name: name
            });
          }
            //redirect profile
            // this.props.history.push('/profile');
        }
        });
        window.addEventListener("resize", this.handleResizeBar);
        this.handleResizeBar();
        this.setState({opportunitiesLoaded:false});
        KomunidadApi.getOpportunities().then((res) =>{
            const opportunities ={
                opportunity1Title: res?.opportunities?.opportunity1Title || '',
                opportunity1Text: res?.opportunities?.opportunity1Text || '',
                opportunity1Link: res?.opportunities?.opportunity1Link || '',
                opportunity1Image: res?.opportunities?.opportunity1Image || '',
                opportunity2Title: res?.opportunities?.opportunity2Title || '',
                opportunity2Text: res?.opportunities?.opportunity2Text || '',
                opportunity2Link: res?.opportunities?.opportunity2Link || '',
                opportunity2Image: res?.opportunities?.opportunity2Image || '',
                opportunity3Title: res?.opportunities?.opportunity3Title || '',
                opportunity3Text: res?.opportunities?.opportunity3Text || '',
                opportunity3Link: res?.opportunities?.opportunity3Link || '',
                opportunity3Image: res?.opportunities?.opportunity3Image || '',
            }
            const opportunitiesArray = [
                {
                    title:opportunities.opportunity1Title,
                    text:opportunities.opportunity1Text,
                    link:opportunities.opportunity1Link,
                    image:opportunities.opportunity1Image
                },
                {
                    title:opportunities.opportunity2Title,
                    text:opportunities.opportunity2Text,
                    link:opportunities.opportunity2Link,
                    image:opportunities.opportunity2Image
                },
                { title:opportunities.opportunity3Title,
                    text:opportunities.opportunity2Text,
                    link:opportunities.opportunity3Link,
                    image:opportunities.opportunity3Image
                },
            ];
            this.setState({
                opportunities: opportunitiesArray.filter((o) => o.title && o.text && o.link)
            })
        }).catch((ex) =>{

        }).finally(() =>{
            this.setState({opportunitiesLoaded:true});
        })
        this.getVideos();

    }
    getVideos(){
        KomunidadApi.getCreoVideos().then((res) => {
            console.log(res);
            const data = res?.value?.items ? res?.value?.items : [];
            this.setState({creoVideos:data,currentCreoVideo:data[0]});
        }).catch((ex) =>{

        });
    }
    redirectToEditProfile(){
        this.props.history.push('/profile/edit');
    }


    handleHeaderResize(width){
        this.setState({
            sidebarVisible:width <= 500 ? false : true
        });
    }

    handleResizeBar(method) {
        const windowSize = window.innerWidth;
        if(method === 'byClick') {
          this.setState({
            sidebarStatus: !this.state.sidebarStatus,
          })
        } else {
          if(windowSize <= 994) {
            this.setState({
              sidebarStatus: true,
              showTopbar: false
            })
          } else {
            this.setState({
              sidebarStatus: false,
              showTopbar: true
            })
          }
        }
      }

    onExitModal(){
        localStorage.setItem('welcomeModal', 'showed');
    }
    handleSelectedTab(tab){
      // if(tab === "event_tab"){
      //   window.location.reload();
      // }
      this.setState({
        keyEventTabs: tab
      });
    }
    renderCreoVideos(){
        return(
            <div className={"flex"}>
                <div>
                    <div className="card-header bg-white p-3">
                        CREO MX
                    </div>
                    <div className={"yt-selector pt-3"}>
                        {
                            this.state.creoVideos.map((video) =>{
                                const videoThumbnail =  video?.snippet?.thumbnails?.high?.url;
                                const isSelected = this.state.currentCreoVideo?.id === video?.id;
                                return (
                                    <div className={`yt-video ${isSelected? 'yt-selected': ''}`} onClick={() => this.setState({currentCreoVideo:video})}>
                                        <div className={`yt-thumbnail`} style={{backgroundImage:`url(${videoThumbnail})`}}></div>
                                        <div className={"yt-title"}>
                                            {isSelected ? '▶️ ':''}
                                            {video?.snippet?.title}
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div>
                    <div className="card-header bg-white p-3">
                        &nbsp;
                    </div>
                    <div className={'p-3'}>
                        {
                            this.state.currentCreoVideo?.contentDetails?.videoId?
                                <iframe width="600" height="400" src={`https://www.youtube.com/embed/${this.state.currentCreoVideo?.contentDetails?.videoId}`}
                                        title={this.state.currentCreoVideo?.snippet?.title} frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                                : null
                        }
                    </div>


                </div>


            </div>
        )
    }
    renderOpportunities(){
        return(
            <div className={"flex w-100"}>
                <div className={"w-100"}>
                    <div className="card-header bg-white p-3">
                        Oportunidades
                    </div>
                    {
                        this.state.opportunities.length === 0 && this.state.opportunitiesLoaded ?
                            <div className={'p-4'}>
                                Por el momento, no hay oportunidades disponibles.
                            </div> : null
                    }

                    {
                        this.state.opportunities.map((opportunity) =>{
                            return (
                                <div className={'p-4'}>
                                    <div><h5><b>{opportunity.title}</b></h5></div>
                                    {
                                        opportunity.image  ?
                                            <div className={'opportunity_img'} style={{backgroundImage:`url(${opportunity.image})`}}>
                                            </div> : null
                                    }
                                    <div><p>{opportunity.text}</p></div>
                                    <div>Enlace:&nbsp;<a href={opportunity.link}>{opportunity.link}</a></div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        )
    }
    renderSelectedTab(tab){
      return (
        <div>
          <div className="row">
            <div className="col-12">
                <div className="card">
                    {this.renderCreoVideos()}
                </div>
                <div className={"card"}>
                    {this.renderOpportunities()}
                </div>
            </div>
          </div>
        </div>
      );
    }

  renderLiveIframe(){

  }
  renderLiveIframe_2(){
    const iframe_2 = this.state.iframe_2;
    if(this.state.loading === false && iframe_2 !== ""){
        return(
          <div className="container text-center pt-3">
                <div className="live-frame">
                  <iframe src={iframe_2} className="stream-frame" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                </div>
          </div>
        )
    }else{
      return this.renderNotAvailable()
    }
  }
  renderScheduleIframe(){
    const schedule = this.state.schedule;
    if(this.state.loading === false && schedule !== ""){
        if(this.state.selectedId[0] === "6165b4afd7024e217389f618"){
          return(
            <div className="container text-center pt-3">
                  <div className="container" style={{height:"4000px"}}>
                    <iframe src={schedule} className="" style={{height:"100%", width:"100%"}} frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                  </div>
            </div>
          )
        }else{
          return(
            <div className="container text-center pt-3">
                  <div className="live-frame">
                    <iframe src={schedule} className="stream-frame" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                  </div>
            </div>
          )
        }
    }else{
        return this.renderNotAvailable()
    }
  }
  renderPartnershipIframe(){
      const partnership = this.state.partnership;
      if(this.state.loading === false && partnership !== ""){
          return(
              <div className="container text-center pt-3">
                   <div className="live-frame">
                      <iframe src={partnership} className="stream-frame" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                   </div>
              </div>
          )
      }else{
          return this.renderNotAvailable()
      }
  }

  renderNotAvailable(){
    return(
      <div className="text-center m-t-30">
        <h3>Este evento aún no tiene transmisión en vivo</h3>
        <i className="mdi mdi-play m-t-20 m-b-20" style={{fontSize: '50px', color: "#c2c2c2"}}></i>
      </div>
    )
}
    

  openConfirmationModal() {
    let email = this.state.preuser_email;

    if (email !== '') {
      Swal.fire({
        title: 'Confirma tu correo',
        html: `Para desbloquear todo el contenido de CREO MX revisa tu correo electrónico <b>${this.state.preuser_email}</b> (no olvides la carpeta de SPAM) y confirma tu cuenta.`,
        type: 'info',
        confirmButtonText: 'Reenviar correo',
        customClass: {
          confirmButton: 'swalBtnTeal',
          title: 'swal-custom-title',
        },
      }).then((result) => {
        if (result.value) {
          this.resendPrc();
        }
      }); 
    } else {
      this.props.history.push('/sign-up');
    }
  }

  resendPrc() {
    let email = this.state.preuser_email;
    if (email !== '') {
      KomunidadApi.userPreRegister(this.state.preuser_email).then(res => {
        if (res.success) {
          this.swalSuccess(this.state.preuser_email);
        } else {
          this.swalError(this.state.preuser_email);
        }
      }).catch(err => { });
    } else {
      this.props.history.push('/sign-up');
    }
  }

  swalSuccess(email) {
    Swal.fire({
      title: 'Reenvío exitoso',
      html: `Hemos enviado un correo a <b>${email}</b> con instrucciones para activar tu cuenta. (Recuerda revisar tu bandeja de correo no deseado)`,
      type: 'success',
      allowOutsideClick: false,
      allowEscapeKey: false,
      customClass: {
        confirmButton: 'swalBtnTeal',
        title: 'swal-custom-title',
      },
      onOpen: () => {
        Swal.showLoading()
        setTimeout(() => { Swal.hideLoading() }, 5000)
      },
    })
  }

  swalError(email) {
    Swal.fire({
      title: 'Felicidades ya tienes cuenta en Komunidad',
      html: `El correo <b>${email}</b> ya se encuentra registrado, serás redireccionado al login para iniciar sesión`,
      type: 'success',
      allowOutsideClick: false,
      allowEscapeKey: false,
      customClass: {
        confirmButton: 'swalBtnTeal',
        title: 'swal-custom-title',
      },
      onOpen: () => {
        Swal.showLoading()
        setTimeout(() => { Swal.hideLoading() }, 5000)
      },
    }).then(() => {
      this.props.history.push('/');
    })
  }
    onProgramSelected(program){
      let programId = program._id;
      let programName = program.name;
      this.setState({
        selectedId: [programId],
        programName: programName
      })
      // this.handleSelectedTab('schedule_tab')
      return this.getLiveViewByProgram(programId);
  }

  getLiveViewByProgram(programId){
    KomunidadApi.getLiveViewByFreeProgram(programId).then((res) => {
       if(res.success){
         this.setState({iframe:res.iframe,iframe_2:res.iframe_2,schedule:res.schedule,partnership:res.partnership,liveViewLoadState:'success', loading: false});
       }else{
         this.setState({iframe:"",iframe_2:"",schedule:"",partnership:"",liveViewLoadState:'failed', loading: false})
       }
    });
  }


  render(){
   if(this.state.authorized){
     return(
        <div className={"fix-header card-no-border fix-sidebar height100 " + (this.state.sidebarStatus ? "mini-sidebar" : "")} >
         <div id="main-wrapper" className="height100">
            <HeaderFree
              user={this.state.me}
              onBurgerClicked={() => this.handleResizeBar('byClick')}
              onResize={(w) => this.handleHeaderResize(w)}
              hideBurguer={this.state.showTopbar}
            />
            <TopSideBar
              // user={this.state.me}
              freeMode={true}
              hideTopBar={!this.state.showTopbar}
              onClickSidebar={() => this.openConfirmationModal()}
              />
            <SideBar 
              // user={this.state.me}
              freeMode={true} 
              visible={this.state.sidebarStatus} 
              hideSideBar={this.state.showTopbar}
              onClickSidebar={() => this.openConfirmationModal()}
            />
           {/* Page wrapper  */}
           <div className={"page-wrapper color-gray " + (this.state.showTopbar ? 'topbar-wrapper-open':'')}>
               <div className="container-fluid">
                   <div className="row">
                       <div className="col-lg-8 col-md-12 col-xs-12">
                           <div className="card">
                               {this.renderCreoVideos()}
                           </div>
                           <div className="card">
                               {this.renderOpportunities()}
                           </div>
                       </div>
                   </div>
               </div>
           </div>
           {/* End Page wrapper  */}
         </div>
            <Footer />

       </div>
     )
   }else{
     return(null)
   }
  }
}
export default LiveFreeView;
